<template>
  <v-row justify="space-around">
    <v-col sm=12 md=6>
      <LoginComponent/>
    </v-col>
  </v-row>
</template>
<script>
import LoginComponent from '../../components/shared/LoginComponent.vue'

export default {
  name: 'Login',

  components: {
    LoginComponent
  }
}
</script>
